import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import contactRoute from '@pages/contact/contact.routes';
import eventsRoute from '@pages/events/events.routes';
import homeRoute from '@pages/home/home.routes';
import mediasRoute from '@pages/medias/medias.routes';
import merchRoute from '@pages/merch/merch.routes';
import musicRoute from '@pages/music/music.routes';
import MainLayout from '@components/layout/mainLayout/MainLayout';

export default function Routes() {
  const [t] = useTranslation();

  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from={'/'} to={`${t('route_currentLang')}/${t('route_home')}`} />
        <Route
          exact
          path={contactRoute.paths}
          component={() => (
            <MainLayout location={contactRoute.location}>
              <contactRoute.component location={contactRoute.location} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path={eventsRoute.paths}
          component={() => (
            <MainLayout location={eventsRoute.location}>
              <eventsRoute.component location={eventsRoute.location} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path={homeRoute.paths}
          component={() => (
            <MainLayout location={homeRoute.location}>
              <homeRoute.component location={homeRoute.location} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path={mediasRoute.paths}
          component={() => (
            <MainLayout location={mediasRoute.location}>
              <mediasRoute.component location={mediasRoute.location} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path={merchRoute.paths}
          component={() => (
            <MainLayout location={merchRoute.location}>
              <merchRoute.component location={merchRoute.location} />
            </MainLayout>
          )}
        />
        <Route
          exact
          path={musicRoute.paths}
          component={() => (
            <MainLayout location={musicRoute.location}>
              <musicRoute.component location={musicRoute.location} />
            </MainLayout>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}
