import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '@assets/locales/en.json';
import fr from '@assets/locales/fr.json';

export enum Language {
  French = 'fr',
  English = 'en'
}

// export interface ILanguageList {
//   code: Language;
//   label: string;
// }

// export const languageList: ILanguageList[] = [
//   {
//     code: Language.French,
//     label: 'Français'
//   },
//   {
//     code: Language.English,
//     label: 'English'
//   }
// ];

const resources = {
  [Language.French]: {
    translation: fr
  },
  [Language.English]: {
    translation: en
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // whitelist: ['fr', 'en'],
    fallbackLng: Language.French,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['path', 'localStorage', 'navigator']
    }
  });

export default i18n;

export function getCurrentLanguage(): string {
  return i18n.language;
}

export type LanguageReturnType = string[];

export function getLanguagesList(): LanguageReturnType[] {
  return [
    ['Français', Language.French],
    ['English', Language.English]
  ];
}

export function changeCurrentLanguage(langTo: Language): void {
  const langFrom = i18n.language;
  const currentPathname = window.location.pathname.replace(/\/fr\/|\/en\/|\//, '');
  i18n
    .changeLanguage(langTo)
    .then(() => {
      updateUrl(langTo, langFrom, currentPathname);
    })
    .catch((error) => {
      console.log(`error on lang switch: ${error}`);
    });
}

function updateUrl(langTo: Language, langFrom: string, currentPathname: string) {
  const langPathKey = getNewLangPathnameKey(langTo, langFrom, currentPathname);
  const newUrl = `/${langTo}/${i18n.t(langPathKey)}`;
  window.history.replaceState('', '', newUrl);
}

function getNewLangPathnameKey(langTo: Language, fromLang: string, currentPathname: string) {
  const routeObject = i18n.store.data[fromLang].translation;
  let foundKey = '';
  for (const [key, value] of Object.entries(routeObject)) {
    if (key.indexOf('route_') > -1 && value.indexOf(currentPathname) > -1) {
      foundKey = key;
      break;
    }
  }
  return foundKey;
}

export function existsLanguageByCode(code: string): boolean {
  const languages: LanguageReturnType[] = getLanguagesList();
  let exists = false;

  languages.forEach((lang) => {
    if (lang[0].toLowerCase() === code.toLowerCase()) {
      exists = true;
      return;
    }
  });

  return exists;
}
