import React from 'react';
import './footer.scss';
import NavElements from '@components/layout/nav/NavElements';
import LogoNoStepBack from '@components/svg/LogoNoStepBack';
import SvgUse from '@components/svg/SvgUse';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const [t] = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__logo">
          <LogoNoStepBack />
        </div>
        <div className="footer__nav">
          <NavElements />
        </div>
        <div className="footer__social">
          <SvgUse icon="facebook" size="tiny" linkTo={t('nsbLink.facebook')} />
          <SvgUse icon="instagram" size="tiny" linkTo={t('nsbLink.instagram')} />
          <SvgUse icon="youtube" size="tiny" linkTo={t('nsbLink.youtube')} />
        </div>
      </div>
      <div className="footer__copyright">{t('general.copyRight')}</div>
    </footer>
  );
}
