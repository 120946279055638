import React, { createContext, FC, useContext, useEffect } from 'react';

interface IViewport {
  windowWidth: number;
}

const ViewportContext = createContext<IViewport>({
  windowWidth: window.innerWidth
});

export const ViewportProvider: FC = ({ children }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return <ViewportContext.Provider value={{ windowWidth }}>{children}</ViewportContext.Provider>;
};

export function useViewport() {
  return useContext<IViewport>(ViewportContext);
}
