import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '@components/label/langSwitcher/LangSwitcher';

export default function NavElements() {
  const [t] = useTranslation();

  return (
    <ul className="nav__container">
      <li className="nav__item">
        <NavLink className="nav__link" to={`/${t('route_currentLang')}/${t('route_home')}`}>
          {t('nav.home')}
        </NavLink>
      </li>
      <li className="nav__item">
        <NavLink className="nav__link" to={`/${t('route_currentLang')}/${t('route_events')}`}>
          {t('nav.events')}
        </NavLink>
      </li>
      {/*<li className="nav__item">*/}
      {/*  <NavLink className="nav__link" to={`/${t('route_currentLang')}/${t('route_music')}`}>*/}
      {/*    {t('nav.music')}*/}
      {/*  </NavLink>*/}
      {/*</li>*/}
      <li className="nav__item">
        <NavLink className="nav__link" to={`/${t('route_currentLang')}/${t('route_medias')}`}>
          {t('nav.medias')}
        </NavLink>
      </li>
      {/*<li className="nav__item">*/}
      {/*  <NavLink className="nav__link" to={`/${t('route_currentLang')}/${t('route_merch')}`}>*/}
      {/*    {t('nav.merch')}*/}
      {/*  </NavLink>*/}
      {/*</li>*/}
      <li className="nav__item">
        <NavLink className="nav__link" to={`/${t('route_currentLang')}/${t('route_contact')}`}>
          {t('nav.contact')}
        </NavLink>
      </li>
      <li className="nav__item">
        <LangSwitcher />
      </li>
    </ul>
  );
}
