import clsx from 'clsx';
import { MouseEvent } from 'react';
import Icons from '@assets/images/sprite-icons.svg';
import { IconSize } from '@shared/types';
import './svg-use.scss';

interface ISvgUseProps {
  icon: string;
  linkTo?: string;
  className?: string;
  size?: IconSize;
  noWrapper?: boolean;
  title?: string;
  onIconClick?: () => void;
}

export default function SvgUse(props: ISvgUseProps) {
  const svgDom = () => {
    return (
      <svg className="icon">
        <use xlinkHref={`${Icons}#icon-${props.icon}`} />
      </svg>
    );
  };

  const handleIconClick = (event: MouseEvent) => {
    if (props.onIconClick) {
      event.stopPropagation();
      props.onIconClick();
    }
  };

  if (props.noWrapper) {
    return svgDom();
  }

  return (
    <div className={clsx('icon__wrapper', props.size, props.className)}>
      {props.linkTo ? (
        <a
          className="default icon__container"
          href={props.linkTo}
          target="_blank"
          title={props.title}
          onClick={handleIconClick}
          rel="noopener noreferrer"
        >
          {svgDom()}
        </a>
      ) : (
        <div className="icon__container" title={props.title} onClick={handleIconClick}>
          {svgDom()}
        </div>
      )}
    </div>
  );
}
