import React, { Suspense } from 'react';
import { ViewportProvider } from '@shared/hooks/contextViewport';
import Routes from './Routes';
import './app.i18n';
import '../styles/app.scss';

function App() {
  return (
    <div className="app">
      <Suspense fallback={null}>
        <ViewportProvider>
          <Routes />
        </ViewportProvider>
      </Suspense>
    </div>
  );
}

export default App;
