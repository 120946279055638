import React, { ReactNode } from 'react';
import Header from '@components/layout/header/Header';
import Footer from '@components/layout/footer/Footer';
import { EPageLocation } from '@shared/types';
import './mainLayout.scss';

interface IMainLayout {
  location: EPageLocation;
  children: ReactNode;
}

export default function MainLayout(props: IMainLayout) {
  return (
    <div className="full-layout">
      <Header />
      <main className="main">{props.children}</main>
      <Footer />
    </div>
  );
}
