import React from 'react';
import clsx from 'clsx';
import { changeCurrentLanguage, getCurrentLanguage, Language } from '../../../app.i18n';
import './lang-switcher.scss';

interface ILangSwitcherProps {
  className?: string;
}

export default function LangSwitcher(props: ILangSwitcherProps) {
  const langTo = getCurrentLanguage() === 'fr' ? Language.English : Language.French;

  return (
    <button
      className={clsx('button-lang__button', props.className)}
      key={langTo}
      onClick={() => changeCurrentLanguage(langTo)}
    >
      {langTo}
    </button>
  );
}
