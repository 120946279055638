import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import LogoNoStepBack from '@components/svg/LogoNoStepBack';
import NavElements from '@components/layout/nav/NavElements';
import SvgUse from '@components/svg/SvgUse';
import './nav.scss';

interface INavProps {
  isMobileView: boolean;
}

export default function Nav(props: INavProps) {
  const [t] = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    return () => document.body.classList.remove('no-scroll');
  }, []);

  const onToggleMenu = () => {
    if (!menuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="nav">
      <>
        {props.isMobileView ? (
          <div className="nav-mobile">
            <div
              className={clsx('nav-mobile__toggle-btn', { open: menuOpen })}
              onClick={onToggleMenu}
            >
              <span />
              <span />
              <span />
              <span />
            </div>
            {menuOpen && (
              <div className="nav-mobile__backdrop" onClick={() => setMenuOpen(!menuOpen)} />
            )}
            <div className={clsx('nav-mobile__items-wrapper', { open: menuOpen })}>
              <div className="nav-mobile__logo-container">
                <LogoNoStepBack />
              </div>
              <div className="nav-mobile__items-container">
                <NavElements />
              </div>
              <div className="nav-mobile__social-container">
                <SvgUse icon="facebook" size="small" linkTo={t('nsbLink.facebook')} />
                <SvgUse icon="instagram" size="small" linkTo={t('nsbLink.instagram')} />
                <SvgUse icon="youtube" size="small" linkTo={t('nsbLink.youtube')} />
              </div>
            </div>
          </div>
        ) : (
          <NavElements />
        )}
      </>
    </nav>
  );
}
