import { lazy } from 'react';
import { route_merch as page_slug_fr } from '@assets/locales/fr.json';
import { route_merch as page_slug_en } from '@assets/locales/en.json';
import { LOCALE_ROUTES_PREFIX } from '@shared/utils/constants';
import { EPageLocation } from '@shared/types';

const component = lazy(() => import('./Merch'));

const paths = [
  `${LOCALE_ROUTES_PREFIX}/${page_slug_fr}`,
  `${LOCALE_ROUTES_PREFIX}/${page_slug_en}`
];

const location = EPageLocation.merch;

const routes = {
  component,
  paths,
  location
};

export default routes;
