import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoNoStepBack from '@components/svg/LogoNoStepBack';
import Nav from '@components/layout/nav/Nav';
import style from '@styles/base/variables.module.scss';
import './header.scss';
import { useViewport } from '@shared/hooks/contextViewport';

export default function Header() {
  const { windowWidth } = useViewport();
  const isMobileView = windowWidth < parseInt(style['media-sm']);

  return (
    <header className="header">
      <div className="header__logo">
        <NavLink to="/">{!isMobileView ? <LogoNoStepBack /> : <LogoNoStepBack />}</NavLink>
      </div>
      <div>
        <Nav isMobileView={isMobileView} />
      </div>
    </header>
  );
}
